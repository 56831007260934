<template>
  <div class="banner-container full-width">
    <div v-if="url && title" class="title">
      <h1>{{ title }}</h1>
      <f7-link :href="url">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>
    </div>

    <div class="container">
      <f7-swiper v-if="bannerList && bannerList.length > 0" slides-per-view="1" :navigation="bannerList.length > 1 ? true : false" :space-between="0" :autoplay="{ delay: 5000 }" :loop="bannerList.length > 1 ? true : false">
        <f7-swiper-slide v-for="banner in bannerList" :key="'pbn_' + banner.BannerId">
          <FullWidthBannerCardComponent :data="banner" />
        </f7-swiper-slide>
      </f7-swiper>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import FullWidthBannerCardComponent from "@/components/cards/FullWidthBannerCardComponent.vue";

const FullWidthBannerCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "full-width" */ /* webpackMode: "lazy" */ "@/components/cards/FullWidthBannerCardComponent.vue"));

export default defineComponent({
  name: "BannerListComponent",
  components: {
    FullWidthBannerCardComponent,
  },
  props: {
    type: String,
    size: { type: Number, default: 6 },
    title: String,
    url: String,
    mode: String,
  },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");

    let page = 1;
    let lastPage = 1;

    const searchValue = ref("");
    const bannerList = ref([]);

    const getBannerList = async () => {
      let ret = await get("/banner/list", { Type: props.type, page: page, size: props.size, SearchField: "MULTIPLE", SearchType: "LIKE", SearchValue: searchValue.value, LanguageCode: $t.getLanguage(), mode: props.mode || "" });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          bannerList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getBannerList() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      bannerList.value = [];
      page = 1;

      getBannerList();
    };

    onMounted(() => {
      getBannerList();
    });

    return { bannerList, loadMore, search, store };
  },
});
</script>

<style></style>
