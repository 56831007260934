<template>
  <div class="banner-card full" @click="onViewBanner">
    <img :src="$h.getImage(data?.Image, 'BANNER', null, false)" alt="" loading="lazy" />
    <h3 v-if="data?.BannerName && data?.BannerName != ''">{{ $h.formatTrimString(data?.BannerName, 80) }}</h3>
    <p v-if="data?.Description && data?.Description != ''">{{ $h.formatTrimString(data?.Description, 120) }}</p>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { helpers } from "@/utils/helpers.js";

import { f7 } from "framework7-vue";

export default defineComponent({
  name: "BannerCardComponent",
  components: {},
  props: {
    data: Object,
  },
  setup(props) {
    const $f7router = f7.views.get("#main-view").router;

    const onViewBanner = () => {
      if (props?.data?.Link) {
        if (props?.data?.Link.indexOf("http") > -1) {
          helpers.deepLinking($f7router, props?.data?.Link);
        } else {
          if (props?.data?.Link.indexOf("/") > -1) {
            $f7router.navigate(props?.data?.Link);
          } else {
            $f7router.navigate({ name: props?.data?.Link });
          }
        }
      }
    };

    return { onViewBanner };
  },
});
</script>
